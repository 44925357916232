import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { TokenAuth } from '../models/tokenauth';
@Injectable({
  providedIn: 'root'
})
export class TokenAuthService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    baseService: BaseService
  ) {
    super(httpClient, baseService.router, baseService.translate);
  }

  authenticate(tokenAuth: TokenAuth): Observable<any> {
    const url = `${this.apiUrl}TokenAuth/Authenticate`;
    const body = { userNameOrEmailAddress: tokenAuth.userNameOrEmailAddress, password: tokenAuth.password, rememberClient: tokenAuth.rememberClient,identityNum: tokenAuth.identityNum};

    return this.httpClient.post<any>(url, body)
    .pipe(map(response => {
      console.log('Authentication response:', response);
      if (response && response.result) {
        this.setCurrentUser(response.result); // Set user data in BaseService
      }
      return response.result;
    }));
  }

  logout(): void {
    this.setCurrentUser(null); // Clear user data in BaseService
    localStorage.removeItem('currentUser'); // Clear user data from localStorage
  }

  currentUserValue(): any {
    return this.getCurrentUser();
  }
}