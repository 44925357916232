import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiObjectData } from '../models/api-object-data';
import { BaseService } from './base.service';
import { User } from '../../core/models/user';

@Injectable({
    providedIn: 'root'
})

export class UserService {

    controller = 'User';
    constructor(private base: BaseService) {}

    getById(id: any): Observable<ApiObjectData> {
        return this.base.get(id, this.controller + '/GetById');
    }

    getIdnum(idnum: any): Observable<ApiObjectData> {
        return this.base.get(idnum, this.controller + '/GetUserByIdentityNo');
    }

    getALL(userId: any) {
        return this.base.gets(this.controller + '/GetALL/' + userId)
    }

    getUserData(userId: number) {
        return this.base.get(userId, this.controller + '/GetUserData')
    }

    saveUser(obj: User) {
        return this.base.save(this.controller + '/CreateUser', obj)
    }

    getALLUser(userId: any) {
        return this.base.gets(this.controller + '/GetALLUser/' + userId)
    }

    FindAllUsersForApproval(processApprovalId:number,identityNumber:string,name:any,flag:boolean)
    {
        return this.base.gets(this.controller + '/FindAllUsersForApproval/' + processApprovalId+'/'+identityNumber+'/'+name+'/'+flag+'')
    }
}
