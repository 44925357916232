import { Injectable } from '@angular/core';
import  * as SignalR from '@microsoft/signalr';
//import Notiflix from 'notiflix';
import { Observable, Subject } from 'rxjs';
import { ApiObjectData } from '../models/api-object-data';
//import { Request } from '../models/request';
import { BaseService } from './base.service';

@Injectable()
export class HubService {
userid:any;
username:any;
 connectionId: string;
 public data: ApiObjectData[];
constructor(public baseService:BaseService) { }
hubConnection: SignalR.HubConnection;
private taskUpdatedSubject = new Subject<any>();
taskUpdated$ = this.taskUpdatedSubject.asObservable();
public startConnection = (id:any) => {
  debugger
    this.userid =id
    this.hubConnection = new SignalR.HubConnectionBuilder()
    .withUrl(this.baseService.appUrl+'notify',{
        skipNegotiation:true,
        transport: SignalR.HttpTransportType.WebSockets
      }).build();
    this.hubConnection
      .start()
      .then()
      .then(() => this.join())
      .catch()
  }

private join = () => {
    this.hubConnection.invoke('join',this.userid)
    .then((data:any) => {
      // console.log("connId",data);
      this.connectionId = data;
    });
  }

  public updateRequestNotificationDataListener = () => {
    //debugger
    this.hubConnection.on('updateRequestNotification', (data:any) => {
      this.data = data;
      console.log("hereezzzz",data);
      this.taskUpdatedSubject.next(data);
    })
  }



  getMsgFromServer(){
    // this.hubConnection.on("SendMessgae",(msg:any)=>{
    //     Notiflix.Notify.info(
    //         msg,
    //          {
    //            position: 'left-bottom',
    //            timeout: 6000,
    //            backOverlayColor: 'rgba(0,0,0,0.5)',
    //            pauseOnHover: true,
    //            plainText: false,
    //            cssAnimationStyle: 'fade',
    //            fontFamily: 'JF-Flat',
    //            useIcon: true,
    //            fontAwesomeIconStyle: 'basic',
    //            messageMaxLength:350
    //          }
    //        );
    // })
  }
  
//   getApprovNotify():any{
//     this.hubConnection.on("approvNotify",(data:any)=>{
//         return data;
//     });
//   }

//   getExecutNotify():any{
//     this.hubConnection.on("execuNotify",(data:any)=>{
//         return data;
//     });
//   }
}

