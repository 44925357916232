import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Dashboard',
        icon: 'ph-chart-pie-slice',
        link: '/dashboard',
    },
    {
        id: 2,
        label: 'Housing',
        icon: 'ph-house',
        subItems: [
            {
                id: 3,
                label: 'HousingRequests',
                link: '/housing/housing-requests',
                parentId: 2
            },
            {
                id: 4,
                label: 'DischargeRequests',
                link: '/housing/discharge-rquests',
                parentId: 2
            },
            {
                id: 8,
                label: 'inactiveRequests',
                link: '/housing/inactive-requests',
                parentId: 2
            },
            {
                id: 5,
                label: 'housingOrders',
                link: '/housing/housing-orders',
                parentId: 2
            },
            {
                id: 9,
                label: 'HousingEligible',
                link: '/housing/housing-eligible',
                parentId: 2
            },
            {
                id: 10,
                label: 'Residents',
                link: '/housing/residents',
                parentId: 2
            },
            {
                id: 11,
                label: 'Reports',
                link: '/housing/reports',
                parentId: 2
            },
            {
                id: 40,
                label: 'discharge-orders',
                link: '/housing/discharge-orders',
                parentId: 2
            },
            {
                id:42,
                label:'extend-evacuation',
                link:'/housing/extend-evacuation'
            },
            {
                id:43,
                label:'retired',
                link:'/housing/retired'
            },
            // {
            //     id: 12,
            //     label: 'Discharge',
            //     link: '/housing/discharge',
            //     parentId: 2
            // },
            // {
            //     id: 13,
            //     label: 'RetireesAndSponsors',
            //     link: '/housing/retirees',
            //     parentId: 2
            // },
            {
                id: 35,
                label: 'retire-reasons',
                link: '/housing/retire-reasons',
                parentId: 2
            },
            {
                id: 14,
                label: 'Services',
                link: '/housing/services',
                parentId: 2
            },
            {
                id: 15,
                label: 'HousingUnits',
                link: '/housing/housing-units',
                parentId: 2
            },
            {
                id: 16,
                label: 'WaitingLists',
                link: '/housing/waiting-lists',
                parentId: 2
            },
            {
                id: 32,
                label: 'waitings',
                link: 'housing/waitings'
            },
            {
                id:41,
                label:'evacuted',
                link:'/housing/evacuted',
            }
        ]
    },
    {
        id: 17,
        label: 'Maintenance',
        icon: 'ph-wrench', 
        subItems: [
            {
                id: 18,
                label: 'MaintainanceRequests',
                link: '/maintainance/maintainance-requests',
                parentId: 17
            },
            {
                id: 19,
                label: 'work-shop',
                link: '/maintainance/work-shop',
                parentId: 17
            },
            {
                id: 20,
                label: 'repairRquest',
                link: '/maintainance/repair-request',
                parentId: 17
            },


            {
              id:21,
              label:'spareParts',
              link:'/maintainance/spare-parts',
              parentId:17

            },
            {
              id:22,
              label:'receivingOrders',
              link:'/maintainance/receiving-orders',
              parentId:17
            },
            {
                id: 23,
                label: 'regular',
                link: '/maintainance/regular-maintenance',
                parentId: 17
            },
            {
                id:36,
                label:"request-regular-maint",
                link:"/maintainance/request-regular-maint",
                parentId:17
            },

            
        ]
    },
    {
      id:57,
      label:'finance',
      icon:'ph-calculator',
      subItems: [
        {
            id: 58,
            label: 'GeneralLists',
            link: '',
            subItems: 
            [
             {
               id: 59,
               label: 'FinancialDurations',
               link: '/finance/finance-durations',
               parentId:58,
             },
             {
              id: 60,
              label: 'LKPs',
              link: '/finance/lookups-manager',
              parentId:58,
             }
            ],
        },
        {
            id:61,
            label: 'moneyTransactions',
            link: '',
            /*subItems: 
            [
             {
               id: 59,
               label: 'FinancialDurations',
               link: '/finance/finance-durations',
               parentId:58,
             },
             {
              id: 60,
              label: 'LKPs',
              link: '/finance/lookups-manager',
              parentId:58,
             }
            ],*/
        },
    ]

    },
    {
        id: 24,
        label: 'companies',
        icon: 'ph-buildings', 
        subItems: [
            {
                id: 25,
                label: 'companies',
                link: '/companies/companies',
                parentId: 24
            },
            {
                id: 26,
                label: 'companies-contracts',
                link: '/companies/companies-contracts',
                parentId: 24
            },
            {
                id: 27,
                label: 'companies-interms',
                link: '/companies/companies-interms',
                parentId: 24
            },
            {
                id: 28,
                label: 'companies-spreparts',
                link: '/companies/companies-spreparts',
                parentId: 24
            },
        ]
    },
    {
        id: 29,
        label: 'approvals',
        icon: 'ph-thumbs-up', 
        link: '/approvals/approvals-levels',
    },
    {
        id: 46,
        label: 'warehouse',
        icon: 'ph-package', 
        subItems: [
            {
                id: 57,
                label: 'issueOrder',
                link: '/warehouse/issue-order',
                parentId: 46
            },
            {
                id: 56,
                label: 'issuedOrders',
                link: '/warehouse/issued-orders',
                parentId: 46
            },
            {
                id: 58,
                label: 'returnOrder',
                link: '/warehouse/return-order',
                parentId: 46
            },
            {
                id: 59,
                label: 'returnedOrders',
                link: '/warehouse/return-orders',
                parentId: 46
            },
            {
                id: 54,
                label: 'driverHousingrequest',
                link: '/warehouse/driver-housing-request',
                parentId: 46
            },
            {
                id: 55,
                label: 'drivers-requests',
                link: '/warehouse/drivers-requests',
                parentId: 46
            },
            {
                id: 47,
                label: 'warehouses',
                link: '/warehouse/warehouses',
                parentId: 46
            },
            {
                id: 48,
                label: 'categories',
                link: '/warehouse/categories',
                parentId: 46
            },
            {
                id: 49,
                label: 'items',
                link: '/warehouse/items',
                parentId: 46
            },
            {
                id: 50,
                label: 'supplier-classes',
                link: '/warehouse/supplier-classes',
                parentId: 46
            },
            {
                id: 51,
                label: 'suppliers',
                link: '/warehouse/suppliers',
                parentId: 46
            },
            {
                id: 52,
                label: 'warehouseunits',
                link: '/warehouse/units',
                parentId: 46
            },
            {
                id: 53,
                label: 'externalCustomers',
                link: '/warehouse/external-customers',
                parentId: 46
            }
        ]
    },
    {
        id: 30,
        label: 'administration',
        icon: 'ph-user-circle-gear', 
        subItems: [
            {
                id: 31,
                label: 'lookups',
                link: '/administration/lookups',
                parentId: 30
            },
            {
                id: 32,
                label: 'roles',
                link: '/administration/roles',
                parentId: 30
            },
            {
                id: 33,
                label: 'users',
                link: '/administration/users',
                parentId: 30
            },
            // {
            //     id: 34,
            //     label: 'roles-managment',
            //     link: '/administration/roles-managment',
            //     parentId: 30
            // },
            {
                id: 44,
                label: 'actions',
                link: '/administration/actions',
                parentId: 30
            },
            // {
            //     id: 45,
            //     label: 'pageRole',
            //     link: '/administration/page-role',
            //     parentId: 30
            // }
        ]
    },
]