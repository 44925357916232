import { Component, OnInit } from '@angular/core';
import Dynamsoft from 'dwt';
import { WebTwain } from 'dwt/dist/types/WebTwain';
import { ThumbnailViewer, ThumbnailViewerSettings, ViewMode } from 'dwt/dist/types/WebTwain.Viewer';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrl: './scanner.component.scss'
})
export class ScannerComponent implements OnInit {

  private DWObject: WebTwain | undefined;

  constructor() {}

  async ngOnInit(): Promise<void> {
    try {
      await Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', this.onWebTwainReady.bind(this));
      this.DWObject = Dynamsoft.DWT.GetWebTwain('dwtcontrolContainer');
      if (this.DWObject) {
        this.initializeScanner();
      }
    } catch (error) {
      console.error('Error initializing the scanner:', error);
    }
  }

  onWebTwainReady(): void {
    this.DWObject = Dynamsoft.DWT.GetWebTwain('dwtcontrolContainer');
    if (this.DWObject) {
      this.initializeScanner();
    }
  }

  initializeScanner(): void {
    if (this.DWObject) {
      this.DWObject.Viewer.bind(document.getElementById('dwtcontrolContainer') as HTMLElement);
      this.DWObject.Viewer.width = '100%';
      this.DWObject.Viewer.height = '400px';
      this.DWObject.Viewer.show();
    }
  }

  scanDocuments(): void {
    if (this.DWObject) {
      this.DWObject.AcquireImage({
        IfShowUI: false,
        PixelType: Dynamsoft.DWT.EnumDWT_PixelType.TWPT_RGB,
        Resolution: 200,
        IfFeederEnabled: true,
        IfDisableSourceAfterAcquire: true
      }, () => {
        console.log('Scanning completed successfully.');
      }, (errorCode, errorString) => {
        console.error(`Error during scanning: ${errorCode} - ${errorString}`);
      });
    }
  }

  uploadDocuments(): void {
    // Implement your upload logic here
  }
}
