<section class="main_section">
    <div class="dasSec" style="margin-bottom: 20px;">
        <div class="breadcrumb">
            <h5 class="pagename">{{'Dashboard' | translate}}</h5>
        </div>
    </div>

    <div class="dashboard_Cards_first_Container">
        <div class="dashCards"  routerLink="/housing/housing-requests">
            <div class="dashcardBody">
                <div>
                    <div class="dashcardIco dashcardIcoGreen">
                        <i class="ph-house"></i>
                    </div>
                </div>
                <div class="dashcardDetails">
                    <span class="dashcardNumber">{{dashboardData?.houseOrderCount}}</span>
                    <span class="dashcardTitle">{{'NewHousingRequests' | translate}}</span>
                </div>
            </div>
            <div class="dashcardfooter">
                <h6><span>{{'details' | translate}}</span> <i class="ph-arrow-left"></i></h6>
            </div>
        </div>

        <div class="dashCards">
            <div class="dashcardBody">
                <div>
                    <div class="dashcardIco dashcardIcoBlue">
                        <i class="ph-bell"></i>
                    </div>
                </div>
                <div class="dashcardDetails">
                    <span class="dashcardNumber">0</span>
                    <span class="dashcardTitle">{{'NewNotifications' | translate}}</span>
                </div>
            </div>
            <div class="dashcardfooter">
                <h6><span>{{'details' | translate}}</span> <i class="ph-arrow-left"></i></h6>
            </div>
        </div>

        <div class="dashCards">
            <div class="dashcardBody">
                <div>
                    <div class="dashcardIco dashcardIcoRed">
                        <i class="ph-briefcase"></i>
                    </div>
                </div>
                <div class="dashcardDetails">
                    <span class="dashcardNumber">0</span>
                    <span class="dashcardTitle">{{'NewTasks' | translate}}</span>
                </div>
            </div>
            <div class="dashcardfooter">
                <h6><span>{{'details' | translate}}</span> <i class="ph-arrow-left"></i></h6>
            </div>
        </div>

        <div class="dashCards" routerLink="/maintainance/maintainance-requests">
            <div class="dashcardBody">
                <div>
                    <div class="dashcardIco dashcardIcoYellow">
                        <i class="ph-wrench"></i>
                    </div>
                </div>
                <div class="dashcardDetails">
                    <span class="dashcardNumber">{{dashboardData?.maintainceOrderCount}}</span>
                    <span class="dashcardTitle">{{'NewMaintenanceRequests' | translate}}</span>
                </div>
            </div>
            <div class="dashcardfooter">
                <h6><span>{{'details' | translate}}</span> <i class="ph-arrow-left"></i></h6>
            </div>
        </div>
    </div>

    <div class="chart-container">
        <div class="row" style="gap: 0;">
            <div class="col-lg-6 col-md-12 pie" *ngIf="houseOrders?.length > 0">
                <div class="table_card" style="margin-top: 0;">
                    <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptionsPie"></highcharts-chart>
                    <div class="legend-container">
                        <div class="legend">
                          <div class="legend-item">
                            <div class="legend-color" style="background-color: #00A46B;"></div>
                            <div class="legend-label">{{this.houseOrders[0]?.doneOrderCount}} طلبات منفذة</div>
                          </div>
                          <div class="legend-item">
                            <div class="legend-color" style="background-color: #025424;"></div>
                            <div class="legend-label">{{this.houseOrders[0]?.notDoneOrder}} طلبات غير منفذة</div>
                          </div>
                        </div>
                      </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 pie" *ngIf="maintenanceOrders?.length > 0">
                <div class="table_card" style="margin-top: 0;">
                    <highcharts-chart [Highcharts]="Highcharts" [options]="chartMaintenanceOptionsPie"></highcharts-chart>
                    <div class="legend-container">
                        <div class="legend">
                          <div class="legend-item">
                            <div class="legend-color" style="background-color: #00A46B;"></div>
                            <div class="legend-label">{{this.maintenanceOrders[0]?.doneOrderCount}} طلبات منفذة</div>
                          </div>
                          <div class="legend-item">
                            <div class="legend-color" style="background-color: #f7464a;"></div>
                            <div class="legend-label">{{this.maintenanceOrders[0]?.notDoneOrder}} طلبات غير منفذة</div>
                          </div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>

</section>
